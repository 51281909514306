.scroll-to-top {
  background-color: var(--secondary);
  color: #fff;
  right: 40px;
  bottom: 40px;
  position: fixed;
  z-index: 2;
  cursor: pointer;
  border-radius: 2px;
  width: 40px;
  height: 40px;
  box-shadow: 0px;
  border: none;
}

.request_list {
  display: grid;
  grid-template-columns: 30% 20% 10% 30% 10%;
  grid-template-rows: auto;
}
@media screen and (max-width: 767px) {
  .request_list {
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto;
  }
  .product_img_div {
    grid-column: span 4;
  }
}
@media screen and (max-width: 499px) {
  .request_list {
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto;
  }
  .product_img_div {
    grid-column: span 2;
    display: grid !important;
    grid-template-columns: 50% 50% !important;
    grid-template-rows: auto !important;
  }
  .ps_mxw_499 {
    padding-right: 15px;
  }
  .text-right-mxw {
    text-align: right !important;
  }
}

.notification_section{
    min-height: 40vh;
}
.notification_list {
    list-style-type: none;
}
.notification_list li {
    display: flex;
    justify-content: space-between;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 2px;
    background-color: hsl(0deg, 0%, 100%);
    box-shadow: 0.25rem 0.25rem 1.5rem rgb(0 0 0 / 10%);
}
.profile_img,
.sec_img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}
.sec_img {
    border-radius: 2px;;
}

.order_main_div {
  border-radius: 2px;
  border: 1px solid #ebf0f6;
}
.order_list {
  display: grid;
  grid-template-columns: 25% 25% 15% 35%;
  grid-template-rows: auto;
}
.order_item_main_head {
  font-size: 14px;
  font-weight: 600;
  white-space: pre-line;
}
.order_item_Sec_head {
  font-size: 12px;
  font-weight: 600;
  white-space: pre-line;
  color: var(--dim_txt);
}
.order_delete_div {
  justify-content: end;
}
@media screen and (max-width: 659px) {
  .order_list {
    grid-template-columns: 25% 25% 30% 20%;
  }
}
@media screen and (max-width: 499px) {
  .order_list {
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
  }
  .order_delete_div {
    justify-content: start;
  }
}
@media screen and (max-width: 991px) {
  .show_order_fit_on_b_screen {
    display: none;
  }
}
@media screen and (min-width: 991px) {
  .show_order_fit_on_s_screen {
    display: none;
  }
}

.order_detail_list {
  display: grid;
  grid-template-columns: 106px 30% 30% calc(40% - 106px);
  grid-template-rows: auto;
}

@media screen and (max-width: 599px) {
  .order_detail_list {
    grid-template-columns: 106px calc(100% - 106px) !important;
    grid-template-rows: auto auto auto !important;
  }
  .order_img_div {
    grid-row: span 3;
  }
  .order_delete_icon {
    align-self: flex-end;
  }
  .pb-mxw {
    padding-bottom: 0 !important;
  }
  .pt-mxw {
    padding-top: 0 !important;
  }
  .mt-mxw {
    margin-top: 0 !important;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: 0.2s linear;
  font-family: "Red Hat Display", sans-serif;
  letter-spacing: 0.3px;
  // border: 2px solid red;
  // color: var(--heading);
}

a{
  text-decoration: none !important;
  color:var(--primary)
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,
dl,
ol,
ul {
  margin-bottom: 0rem;
}
p {
  margin: 0;
}
//Global css Variables

:root {
  --primary: #2e7d32;
  --secondary: #f73859;
  --bg_green: #deeacc;
  --bg_pink: #fdeded;
  --footer_txt: #181a18;
  --heading: #222222;
  --dim_txt: #777777;
  --header_bg: #f7f7f7;
  --bg_secondary:#ffddf4;
}
.primary {
  color: var(--primary);
}

.secondary {
  color: var(--secondary);
}

.secondary_bg {
  background: var(--secondary);
}
.dim_txt {
  color: var(--dim_txt);
}

//Our containers

@media (min-width: 576px) and (max-width: 767px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 797px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 1197px;
  }
}

//Home.scss
@import "./pages/home/Home.scss";

//border
.br_s_bottom {
  border-bottom: 2px solid var(--secondary);
}
.br_s_primary {
  border-bottom: 2px solid var(--primary);
}
.br_8 {
  border-radius: 2px;
}
.br_15 {
  border-radius: 2px;
}

//Font-size
.fs_12 {
  font-size: 12px;
}
.fs_13 {
  font-size: 13px;
}
.fs_14 {
  font-size: 14px;
}
.fs_15 {
  font-size: 15px;
}
.fs_16 {
  font-size: 16px;
}
.fs_17 {
  font-size: 17px;
}
.fs_24 {
  font-size: 24px;
}
.fs_29 {
  font-size: 29px;
}

//font-weight

.fw_500 {
  font-weight: 500;
}
.fw_600 {
  font-weight: 600;
}

.fw_700 {
  font-weight: 700;
}

//tabs
// .css-1h9z7r5-MuiButtonBase-root{
//   align-items: baseline !important;
//   text-transform: none !important;
//   min-height: 55px !important;
// }
// .css-1h9z7r5-MuiButtonBase-root:hover{
//   color: #f73859 !important;
// }
// .css-1h9z7r5-MuiButtonBase-root.Mui-selected {
//   color: #f73859 !important;
// }
.myTabs .MuiTab-labelIcon {
  min-height: 55px !important;
}
.myTabs .MuiTab-labelIcon {
  justify-content: flex-start !important;
}

.myTabs .MuiTab-labelIcon:hover {
  color: #f73859 !important;
}
.myTabs .MuiTab-labelIcon:active {
  color: #f73859 !important;
}
.myTabs .MuiTab-labelIcon.Mui-selected {
  color: #f73859 !important;
}
.myTabs .MuiTab-labelIcon {
  text-transform: none !important;
}
.css-1p5xt40 {
  width: 100% !important;
}

.myTabs .MuiTab-labelIcon:hover {
  color: var(--secondary) !important;
}
.myTabs .MuiTab-labelIcon:active {
  color: var(--secondary) !important;
}
.myTabs .MuiTab-labelIcon.Mui-selected {
  color: var(--secondary) !important;
  font-weight: 700 !important;
}
.myTabs .MuiTab-labelIcon {
  text-transform: none !important;
}
.css-1p5xt40 {
  width: 100% !important;
}
.css-1aquho2-MuiTabs-indicator {
  background-color: var(--secondary) !important;
}
.css-10d9dml-MuiTabs-indicator {
  background-color: var(--secondary) !important;
}
.css-1gsv261 {
  border-bottom: none !important;
}
// .css-w8dmq8 {
//   // width: fit-content !important;
// }
.css-1h9z7r5-MuiButtonBase-root {
  padding: 0px !important;
  min-width: 0px !important;
}
.css-heg063-MuiTabs-flexContainer {
  align-items: center !important;
  flex-wrap: wrap;
}
.css-1h9z7r5-MuiButtonBase-root.Mui-selected {
  color: #f73859 !important;
}
.myTabs .MuiTab-labelIcon {
  font-weight: 500 !important;
  color: var(--footer_tx) !important;
}
.css-1h9z7r5-MuiButtonBase-root {
  font-weight: 700 !important;
  text-transform: capitalize !important;
  letter-spacing: 1px !important;
}
.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
  z-index: 999999999999999 !important;
}
TabPanel {
  flex-grow: 1 !important;
}
.myTabs .MuiTab-labelIcon {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.myTabs .MuiTabs-root {
  width: 230px !important;
  max-width: 230px !important;
  min-width: 230px !important;
}

.myTabs .MuiTab-labelIcon:nth-child(1) {
  border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
}
.pro_tabs .Mui-selected {
  color: var(--secondary) !important;
  font-weight: 700 !important;
}
.pro_tabs .MuiTab-root {
  color: #000;
  text-transform: none;
}
.form-check-input:checked {
  background-color: var(--secondary) !important;
  border-color: var(--secondary) !important;
}
.pointer {
  cursor: pointer;
}

.list-img {
  width: 90px;
  height: 65px;
  object-fit: cover;
  border-radius: 2px;
}
.list-img-cart {
  width: 80px;
  height: 55px;
  object-fit: cover;
  border-radius: 2px;
}

.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem var(--secondary) (13 110 253 / 25%);
}

@media screen and (max-width: 425px) {
  .Toastify__toast-container {
    max-width: 90% !important;
    margin: 10px auto !important;
  }
}

@media screen and (max-width: 1200px) {
  .container {
    max-width: 98% !important;
  }
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1600px !important;
  }
}

// body {
//   scrollbar-width: none;
// }

// body::-webkit-scrollbar {
//   width: none;
// }
.highlighted{
  background-color: yellow !important;
}

.inputStyle {
  width: 3rem !important;
  height: 3rem;
  margin: 20px 1rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
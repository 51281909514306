.announcement-bar {
    background-color: #ebf0f6;
  }
  .announcement-bar-inner {
    // color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .announcement-bar a{
color:var(--primary) !important;
  }
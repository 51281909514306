.dropdown_menu {
  position: relative !important;
}

.dropdown_menu_list {
  position: absolute;
  top: 50px !important;
  left: 0px;
  opacity: 0;
  transition: 0.2s linear;
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 10%),
    0 10px 10px -5px rgb(0 0 0 / 4%);
  background: #fff;
  border-radius: 2px;
  padding: 20px;
  list-style-type: none;
  z-index: 999999;
  visibility: hidden;
}

.dropdown_menu:hover .dropdown_menu_list {
  top: 80px;
  opacity: 1;
  top: 40px !important;
  transition: 0.2s linear;
  visibility: visible;
}

.MuiButton-root span {
  display: inline !important;
}
.rotate_icon_on_hover {
  transition: 0.2s linear;
}
.dropdown_menu:hover .rotate_icon_on_hover {
  transform: rotate(180deg);
  transition: 0.2s linear;
}
.pt_8px {
  padding-top: 8px !important;
}
.card_img {
  border-radius: 2px;
  width: 100%;
  height: 200px;
  // padding:0px 30px!important;
  overflow: hidden !important;
}

.img_div {
  padding: 0 20px 0 0px;
}
.border_icons_cat {
  // border: 1px solid var(--secondary);
  // box-shadow: 0 10px 30px 0 rgb(31 45 61 / 10%);
  border-radius: 2px;
  padding: 30px 20px;
  border: 1px solid rgba(226, 226, 226, 1) !important;
}
.category_icons {
  color: var(--secondary);
  font-size: 6rem !important;
}
.typ {
  padding: 10px 0px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
}

.top_offer_card {
  background: rgba(255, 255, 255, 1);
  border-width: 1px;
  border-style: solid;
  border-color: rgba(226, 226, 226, 1);
  // padding: 5px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  border: 1px solid rgba(226, 226, 226, 1) !important;
}

.shadow_box_s:hover{
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

// .top_offer_card:hover .top_offer_card_inner_div {
//   // opacity: 0.4;
//   // filter: alpha(opacity=40); /* msie */
//   // background-color: #000;
//   // transform: scale(1.1);
//   // transform: scale(0.9);
// }

.alpha {
  opacity: 0.4;
  filter: alpha(opacity=40); /* msie */
  background-color: #000;
}
// .left_div_top_offer {
//   position: absolute;
//   top: 10px;
//   right: 0px;
//   visibility: hidden;
//   opacity: 0;
// }

 .left_div_top_offer {
  position: absolute;
    top: 8px;
  right: 10px;
  visibility: visible;
  opacity: 1;
  // background: #fff;
  padding: 10px 10px;
}
.top_offer_card .right_div_top_offer {
  position: absolute;
  top: 10px;
  left: 20px;
}

.badge_div {
  background: var(--primary);
  padding: 2px 10px;
  border-radius: 2px;
}
.primary_col {
  color: var(--primary) !important;
}
.second_txt_col {
  color: var(--secondary) !important;
}

.second_col {
  background-color: var(--secondary) !important;
}
.right_div_top_offer {
  position: absolute;
  top: 10px;
  left: 20px;
}
.react-multi-carousel-list {
  padding-bottom: 10px;
}

.service_title {
  font-weight: 700;
  text-transform: capitalize;
  font-size: 18px;
  line-height: 24px;
}
.service_subtitle {
  line-height: 24px;
  margin-block-start: 10px;
}

.border_bottom_with_padding {
  border-bottom: 2px solid var(--bg_green);
}

.home-big-slider .react-multi-carousel-list {
  padding-bottom: 0px;
}

.home-big-slider .react-multi-carousel-dot button {
  border: none;
  background-color: var(--bg_green);
}

.home-big-slider .react-multi-carousel-dot--active button {
  background-color: var(--primary);
}

.flex-even {
  flex: 1;
}

@media screen and (max-width: 767px) {
  .card_img {
    border-radius: 2px;
    width: 100%;
    height: 200px !important;

    // padding:0px 30px!important;
    overflow: hidden !important;
  }
}

.Tick_div {
  position: absolute;
  top: 40px;
  left: 20px;
}

.bread_cum {
  background: var(--bg_green);
  display: inline-block;
  padding: 20px;
  border-radius: 2px;
}
.product_Name {
  white-space: nowrap;
  /* overflow: auto; */
  text-overflow: ellipsis;
  /* width: 10px; */
  overflow: hidden;
}
.dynamic_content a {
  color: var(--primary);
}
.category_box:hover{
  scale: 0.9;
  border:1px solid var(--primary) !important;

}



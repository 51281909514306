.img_thumbnail {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 6px 12px -4px rgb(40 11 18 / 10%);
}
// .img_thumbnail  img{
//   height:300px !important
// }

.yellow_color {
  color: rgb(245, 219, 73);
  font-size: 13px !important;
}

.fs {
  font-size: 13px !important;
  color: grey;
}

.numbr_div {
  // border: 1px solid grey;
  height: 20px;

  width: 100px;
  text-align: center;
}

.add_btn {
  padding: 2px 20px !important;
  border: 1px solid grey !important;
  // font-size: 11px !important;
  color: black !important;
  text-transform: none;
}

.buy_btn {
  // background-color: var(--primary) !important;
  // font-size: 11px !important;
  color: #fff !important;
  padding: 2px 20px !important;
}

.imgdata_div {
  height: 250px !important;
  // overflow: auto;
}

.img_imgdata {
  height: 200px !important;
  width: 100%;
}

//tabs css
// .tab_btn {

// border-radius: 2px !important;
// background-color: var(--primary) !important;
// color: var( --bg_pink) !important;
// width: 120px !important;
// min-height: 30px !important;
// padding: 0;

.reviwe_div {
  margin: 0 auto;
}
.css-w8dmq8 {
  width: 100% !important;
}

.product_img {
  width: 1500px !important;
}

.product_name {
  font-size: 28px;
  font-weight: 700;
}
.border_bottom_with_padding_in_product {
  // padding-bottom: 76px;
  border-bottom: 2px solid var(--bg_green);
}
.border_top_with_padding_in_product {
  border-top: 2px solid var(--bg_green);
}

.quantity_input {
  width: 20px;
  border: none;
  text-align: center;
}

.quantity_input:focus {
  outline: none;
}

.price_input {
  border: none;
}

.price_input:focus {
  outline: none;
}

.off_text_percent {
  background-color: #edf9ee;
  color: #1aab2a;
  padding-left: 4px;
  padding-right: 4px;
}

.related_products_div {
  max-height: 50vh;
  overflow: auto;
}

@media screen and (max-width: 767px) {
}

@media screen and (min-width: 768px) {
}

.overlay_bg_fav {
  background: var(--bg_pink);
  height: 100%;
  overflow-y: auto;
  padding: 15px;
  position: fixed;
  left: 100%;
  top: 0;
  width: 400px;
  max-width: 90%;
  z-index: 999999999999;
  box-shadow: 0 0 10px rgb(26 26 26 / 15%) var(--primary) !important;
}

.primary_btn {
  background-color: var(--primary) !important;
  border: none !important;
  border-radius: 2px !important;
  color: #fff !important;
  cursor: pointer !important;
  display: inline-block !important;
  display: block !important;
  font-size: 14px !important;
  font-style: inherit !important;
  font-weight: inherit !important;
  font-weight: 700 !important;
  letter-spacing: 0 !important;
  line-height: 18px !important;
  line-height: 1em !important;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  max-height: 35px !important;
  outline: 0 !important;
  padding: 10px 20px 11px !important;
  transition: all 0.2s ease-in-out !important;
  white-space: nowrap !important;
  text-transform: none !important;
}


// .primary_btn:hover {
//   background:var(--bg_green) !important;
//   color: var(--primary) !important;
//   // transform: scale(0.9);
// }

.primary_bold_btn {
  background-color: var(--primary) !important;
  border: none !important;
  border-radius: 2px !important;
  color: #fff !important;
  cursor: pointer !important;
  display: inline-block !important;
  display: block !important;
  font-size: 16px !important;
  font-style: inherit !important;
  font-weight: inherit !important;
  font-weight: 700 !important;
  letter-spacing: 1px !important;
  line-height: 18px !important;
  line-height: 1em !important;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);

  // max-height: 35px !important;
  outline: 0 !important;
  padding: 15px 25px 15px !important;
  transition: all 0.2s ease-in-out !important;
  white-space: nowrap !important;
  text-transform: none !important;
}

// .primary_bold_btn:hover {
//   color: var(--primary) !important;
//   background-color:#fff !important;
// }

.greyButton {
  background-color: var(--header_bg) !important;
  border: 1px solid var(--header_bg) !important;
  border-radius: 2px !important;
  color: #000 !important;
  cursor: pointer !important;
  display: inline-block !important;
  display: block !important;
  font-size: 14px !important;
  font-style: inherit !important;
  font-weight: inherit !important;
  font-weight: 700 !important;
  letter-spacing: 0 !important;
  line-height: 18px !important;
  line-height: 1em !important;

  max-height: 35px !important;
  outline: 0 !important;
  padding: 10px 22px 12px !important;
  transition: all 0.2s ease-in-out !important;
  white-space: nowrap !important;
  text-transform: none !important;
}

// .greyButton:hover {
//   background-color: var(--header_bg) !important;
// }

.outline_btn {
  // background-color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  border-radius: 2px !important;
  color: var(--primary) !important;
  cursor: pointer !important;
  display: inline-block !important;
  display: block !important;
  font-size: 14px !important;
  font-style: inherit !important;
  font-weight: inherit !important;
  // font-weight: 700 !important;
  letter-spacing: 0 !important;
  line-height: 18px !important;
  line-height: 1em !important;
  max-height: 35px !important;
  outline: 0 !important;
  padding: 8px 20px !important;
  transition: all 0.2s ease-in-out !important;
  white-space: nowrap !important;
  text-transform: none !important;
}

// .outline_btn:hover {
//   background-color: var(--primary) !important;
//   color:#fff !important;
//   transform: scale(0.9);
// }

.secondary_btn {
  background-color: var(--secondary) !important;
  border: none !important;
  border-radius: 2px !important;
  color: #fff !important;
  cursor: pointer !important;
  display: inline-block !important;
  display: block !important;
  font-size: 14px !important;
  font-style: inherit !important;
  font-weight: inherit !important;
  font-weight: 700 !important;
  letter-spacing: 0 !important;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  max-height: 35px !important;
  outline: 0 !important;
  padding: 5px 10px 5px !important;
  transition: all 0.2s ease-in-out !important;
  white-space: nowrap !important;
  text-transform: none !important;
}
// .secondary_btn:hover { 
//   color:var(--secondary) !important;
//   background-color: var(--bg_secondary) !important;
// }

.loader_btn {
  display: inline-block !important;
  display: block !important;
  font-size: 14px !important;
  font-style: inherit !important;
  font-weight: inherit !important;
  font-weight: 700 !important;
  letter-spacing: 0 !important;

  max-height: 35px !important;
  outline: 0 !important;
  padding: 8px 10px 5px !important;
  transition: all 0.2s ease-in-out !important;
  white-space: nowrap !important;
  text-transform: none !important;
}

.reg_sm_field {
  border-radius: 2px;
  background-color: #ebf0f6;
  min-height: 60px;
  box-shadow: none;
  border: 1px solid #ebf0f6;
  padding: 10px 20px;
  color: #111;
  flex-grow: 1;
  flex-basis: 0;
}
.reg_sm_field:focus {
  outline: 0px;
}
select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  color: rgba(0, 0, 0, 0.54) !important;
}
.paswword_icon{
  position:absolute !important;
  top:10px;
  right:10px;
}
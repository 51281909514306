.overlay_bg {
  background: #fff;
  height: 100%;
  overflow-y: auto;
  padding: 15px;
  position: fixed;
  left: 100%;
  top: 0;
  width: 400px !important;
  max-width: 90%;
  z-index: 999999999999;
  box-shadow: 0 0 10px rgb(26 26 26 / 15%) var(--primary) !important;
}
.full_w_bg_h:before {
  background: rgb(0 0 0 / 50%);
  content: "";
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition: var(--DTBaseTransition);
  z-index: 5;
  opacity: 0;
  visibility: hidden;
}
.full_w_bg_v:before {
  background: rgb(0 0 0 / 50%);
  content: "";
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition: var(--DTBaseTransition);
  z-index: 5;
  opacity: 1;
  visibility: visible;
}
.open_cart {
  transform: translateX(-100%);
  opacity: 1;
  visibility: visible;
}

.close_cart {
  transform: translateX(-90%);
  opacity: 0;
  visibility: hidden;
}

.added-items {
  overflow-y: auto;
}

.borderbottom {
  border-bottom: 1px solid #dbdbdb;
}

.color-primary {
  color: var(--primary);
}

.fs-7 {
  font-size: 14px !important;
}

.d-ico {
  color: var(--secondary);
}

.quantity-btn {
  padding: 5px !important;
}

.wh-30px {
  width: 30px !important;
  height: 30px !important;
}
.total-sec {
  position: fixed;
  bottom: -2px;
  width: 503px;
  left: 11px;
  max-width: 100%;
  background-color: var(--bg_green);
  z-index: 99999999 !important;
  // height: 12vh;
  padding-top: 6px !important;
}

.min_h_cart {
  min-height: 70vh;
}

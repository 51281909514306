.login_field {
  border-radius: 2px;
  background-color: #ebf0f6;
  min-height: 60px;
  box-shadow: none;
  border: 1px solid #ebf0f6;
  padding: 10px 20px;
  color: #111;
  width: 500px;
  max-width: 100%;
}
.w_social_div {
  width: 500px;
  max-width: 100%;
}
.login_btns {
  width: 500px;
  max-width: 100%;
}
.login_field:focus {
  outline: 0px;
}

.hover_a:hover {
  color: var(--primary) !important;
}

.hover_a_s:hover {
  color: var(--secondary) !important;
}

.required {
  color: var(--secondary);
  position: absolute;
  top: 0px;
  left: 10px;
  z-index: 99;
}

.main_footer {
  background: var(--header_bg) !important;
}

.footer_padd {
  padding: 40px 0px;
}

.footer_txt {
  font-size: 14px;
  line-height: 2;
  color: #000;
}

.copy_right_txt {
  font-size: 14px;
  line-height: 2;
  color: #fff;
}

.footer_link {
  text-decoration: none;
}

.footer_link:hover {
  color: var(--primary);
  font-weight: 600;
  transform: translateX(6px);
}

.bt_green {
  border-top: 4px solid var(--primary);
}

.app-link {
  margin-left: -9px;
}

.copy_right {
  background: #232629;
}

.footer_bg {
  // width: 100%;
  // height: 100%;
  z-index: 0;
  position: absolute;
  // top: 0px;
  // left: 0px;
  // right: 0px;
  height: 20px;
  opacity: 0.7;
  bottom: 0px;
}


.custom-shape {
  width: 500px;
  height: 100px;
  position: absolute;
  right: -32px;
  bottom: -70px;
  background-color: var(--primary);
  transform: rotate(168deg);
  opacity: 0.1;
}

.custom-shape2 {
  width: 500px;
  height: 100px;
  position: absolute;
  right: -32px;
  bottom: -111px;
  background-color: var(--primary);
  transform: rotate(162deg);
  opacity: 0.2;
}


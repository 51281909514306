@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}

// .wrapper {
//     //   background: #171c24;
//     //   position: fixed;
//     // width: 100%;
// }

.wrapper nav {
  position: relative;
  display: flex;
  //   max-width: calc(100% - 200px);
  //   margin: 0 auto;
  height: 40px;
  align-items: center;margin-bottom: 5px;
  justify-content: space-between;
}

// nav .content{
//   display: flex;
//   align-items: center;
// }
// nav .content .links{
// //   margin-left: 80px;
// //   display: flex;
// }
// .content .logo a{
//   color: #fff;
//   font-size: 30px;
//   font-weight: 600;
// }
.content .links li {
  list-style: none;
  line-height: 40px;
}

.content .links li a,
.content .links li label {
  color: #fff !important;
  font-size: 18px;
  font-weight: 500;
  padding: 3px 17px;
  border-radius: 2px;
  // transition: all 0.3s ease;
}

// .content .links li label{
//   display: none;
// }
// .content .links li a:hover,
// .content .links li label:hover{
//   background: #323c4e;
// }
// .wrapper .search-icon,
// .wrapper .menu-icon{
//   color: #fff;
//   font-size: 18px;
//   cursor: pointer;
//   line-height: 70px;
//   width: 70px;
//   text-align: center;
// }
// .wrapper .menu-icon{
//   display: none;
// }
// .wrapper #show-search:checked ~ .search-icon i::before{
//   content: "\f00d";
// }

// .wrapper .search-box{
//   position: absolute;
//   height: 100%;
//   max-width: calc(100% - 50px);
//   width: 100%;
//   opacity: 0;
//   pointer-events: none;
//   transition: all 0.3s ease;
// }
// .wrapper #show-search:checked ~ .search-box{
//   opacity: 1;
//   pointer-events: auto;
// }
// .search-box input{
//   width: 100%;
//   height: 100%;
//   border: none;
//   outline: none;
//   font-size: 17px;
//   color: #fff;
//   background: #171c24;
//   padding: 0 100px 0 15px;
// }
// .search-box input::placeholder{
//   color: #f2f2f2;

// .search-box .go-icon{
//   position: absolute;
//   right: 10px;
//   top: 50%;
//   transform: translateY(-50%);
//   line-height: 60px;
//   width: 70px;
//   background: #171c24;
//   border: none;
//   outline: none;
//   color: #fff;
//   font-size: 20px;
//   cursor: pointer;
// }
// .wrapper input[type="checkbox"]{
//   display: none;
// }

/* Dropdown Menu code start */
.content .links ul {
  position: absolute;
  background: #fff;
  top: 40px;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  display: none;
  z-index: -99999999;
  box-shadow: 0 10px 30px 0 rgb(31 45 61 / 10%);
  // box-shadow: 2px 2px 5px solid black !important;
  //  border: 1px solid grey;
  // left: -51px;
}

.content .links ul li a {
  display: block;
  width: 100%;
  line-height: 30px;
  border-radius: 0px !important;
  color: rgb(10, 1, 1) !important;
}

.content .links ul ul {
  position: absolute;
  top: 0;
  z-index: -999999999 !important;
  // right: calc(-100% + 8px);
}

.content .links ul li {
  position: relative;
}

// .content .links ul li:hover ul {
//     top: 0;
// }
.content .links li:hover > ul {
  top: 40px;
  opacity: 1;
  visibility: visible;
  display: block;
  height: auto;
  z-index: 999999999 !important;
  // transition: all 0.3s ease;
}
.content .links li:hover .icon_cat_rot {
  transform: rotate(180deg);
  transition: 0.2s linear;
}
.icon_cat_rot {
  transition: 0.2s linear;
}
.ui_list_1 {
  z-index: 999999 !important;
  width: 200px;
  padding: 5px;
}

.content .links ul .main_drop:hover .ui_list_1 {
  top: 0;
  visibility: visible;
}
.more_list {
  // width: 700px;
  left: 198px !important;
  right: 0;
  top: 0px !important;
  opacity: 0;
  visibility: hidden;
}
.content .links ul .baby_need:hover .more_list {
  width: 700px;
  // left: 139px !important;
  left: 198px !important;
  right: 0;
  top: -37px !important;
  opacity: 1;
  visibility: visible;
}
.more_list_2 {
  width: 700px;
  left: 198px !important;
  right: 0;
  top: -73px !important;
  column-width: 160px;
  column-gap: 10px;
  opacity: 0;
  visibility: hidden;
}

.content .links ul .personal_care:hover .more_list_2 {
  width: 700px;
  left: 198px !important;
  right: 0;
  top: -73px !important;
  column-width: 160px;
  column-gap: 10px;
  opacity: 1;
  visibility: visible;
}
.more_list_3 {
  width: 700px;
  left: 198px !important;
  right: 0;
  top: -109px !important;
  opacity: 0;
  column-width: 160px;
  column-gap: 10px;
  visibility: hidden;
}
.content .links ul .care_list:hover .more_list_3 {
  width: 700px;
  left: 198px !important;
  right: 0;
  top: -109px !important;
  opacity: 1;
  visibility: visible;
}
.more_list_4 {
  width: 700px;
  left: 198px !important;
  right: 0;
  top: -145px !important;
  opacity: 0;
  visibility: hidden;
  column-width: 160px;
  column-gap: 10px;
}
.content .links ul .otc_list:hover .more_list_4 {
  width: 700px;
  left: 198px !important;
  right: 0;
  top: -145px !important;
  opacity: 1;
  visibility: visible;
  column-width: 160px;
  column-gap: 10px;
}
.more_list_5 {
  width: 700px;
  left: 198px !important;
  right: 0;
  top: -181px !important;
  opacity: 0;
  visibility: hidden;
  column-width: 160px;
  column-gap: 10px;
}
.content .links ul .vitamins_list:hover .more_list_5 {
  width: 700px;
  left: 198px !important;
  right: 0;
  top: -181px !important;
  opacity: 1;
  visibility: visible;
  column-width: 160px;
  column-gap: 10px;
}
.more_list_6 {
  width: 700px;
  left: 198px !important;
  right: 0;
  top: -217px !important;
  opacity: 0;
  visibility: hidden;
  column-width: 160px;
  column-gap: 10px;
}
.content .links ul .house_list:hover .more_list_6 {
  width: 700px;
  left: 198px !important;
  right: 0;
  top: -217px !important;
  opacity: 1;
  visibility: visible;
  column-width: 160px;
  column-gap: 10px;
}
.more_list_7 {
  width: 700px;
  left: 198px !important;
  right: 0;
  top: -253px !important;
  opacity: 0;
  visibility: hidden;
  column-width: 160px;
  column-gap: 10px;
}
.content .links ul .Diabetice_list:hover .more_list_7 {
  width: 700px;
  left: 198px !important;
  right: 0;
  top: -253px !important;
  opacity: 1;
  visibility: visible;
  column-width: 160px;
  column-gap: 10px;
}
.fs_11 {
  font-size: 13px !important;
  margin-right: 5px;
}
// .more_list_8{
//     width: 700px;
//     left: 139px !important;
//     right: 0;
//     top: -360px !important;
//     opacity: 0;
//     visibility: hidden;
//     column-width: 160px;
//     column-gap: 10px;

// }
// .content .links ul .needs_list:hover .more_list_8{
//     width: 700px;
//     left: 139px !important;
//     right: 0;
//     top: -360px !important;
//     opacity: 1;
//     visibility: visible;
//     column-width: 160px;
//     column-gap: 10px;

// }
.content .links li ul .arrow-left {
  position: absolute;
  top: 10px;
  right: 0;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #656565 !important;
  opacity: 0;
}

.content .links li ul li:hover .arrow-left {
  position: absolute;
  top: 10px;
  right: 0;

  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #656565 !important;
  opacity: 1;
  display: block;
}
.content .links li ul li .fs_color {
  // color: black;
  font-weight: 400;
  color: #212529;
  white-space: nowrap;
}
.content .links li ul li ul li a {
  // color: black;
  font-weight: 400;
  color: #212529;
  white-space: nowrap;
}

.content .links li ul li:hover .fs_color {
  color: #1e2125;
  background-color: #e9ecef;
}

/* Responsive code start */
// @media screen and (max-width: 1250px){
//   .wrapper nav{
//     max-width: 100%;
//     padding: 0 20px;
//   }
//   nav .content .links{
//     margin-left: 30px;
//   }
//   .content .links li a{
//     padding: 8px 13px;
//   }
//   .wrapper .search-box{
//     max-width: calc(100% - 100px);
//   }
//   .wrapper .search-box input{
//     padding: 0 100px 0 15px;
//   }
// }

// @media screen and (max-width: 900px){
//   .wrapper .menu-icon{
//     display: block;
//   }
//   .wrapper #show-menu:checked ~ .menu-icon i::before{
//     content: "\f00d";
//   }
//   nav .content .links{
//     display: block;
//     position: fixed;
//     background: #14181f;
//     height: 100%;
//     width: 100%;
//     top: 70px;
//     left: -100%;
//     margin-left: 0;
//     max-width: 350px;
//     overflow-y: auto;
//     padding-bottom: 100px;
//     transition: all 0.3s ease;
//   }
//   nav #show-menu:checked ~ .content .links{
//     left: 0%;
//   }
//   .content .links li{
//     margin: 15px 20px;
//   }
//   .content .links li a,
//   .content .links li label{
//     line-height: 40px;
//     font-size: 20px;
//     display: block;
//     padding: 8px 18px;
//     cursor: pointer;
//   }
//   .content .links li a.desktop-link{
//     display: none;
//   }

//   /* dropdown responsive code start */
//   .content .links ul,
//   .content .links ul ul{
//     position: static;
//     opacity: 1;
//     visibility: visible;
//     background: none;
//     max-height: 0px;
//     overflow: hidden;
//   }
//   .content .links #show-features:checked ~ ul,
//   .content .links #show-services:checked ~ ul,
//   .content .links #show-items:checked ~ ul{
//     max-height: 100vh;
//   }
//   .content .links ul li{
//     margin: 7px 20px;
//   }
//   .content .links ul li a{
//     font-size: 18px;
//     line-height: 30px;
//     border-radius: 2px!important;
//   }
// }

// @media screen and (max-width: 400px){
//   .wrapper nav{
//     padding: 0 10px;
//   }
//   .content .logo a{
//     font-size: 27px;
//   }
//   .wrapper .search-box{
//     max-width: calc(100% - 70px);
//   }
//   .wrapper .search-box .go-icon{
//     width: 30px;
//     right: 0;
//   }
//   .wrapper .search-box input{
//     padding-right: 30px;
//   }
// }

// .more_list {
//     width: 700px;
//     left: 0px;
//     right: 0;
//     top: -48px !important;
// }

// .more_list_2 {
//     width: 700px;
//     left: 60px;
//     right: 0;
//     top: -98px !important;
//     column-width: 160px;
//     column-gap: 10px;
// }

// .more_list_3 {
//     width: 700px;
//     left: 60px;
//     right: 0;
//     top: -145px !important;
// }

// .ui_list_1 {
//     z-index: 999999 !important;
//     width: 170px;
// }

.b_bottom_p_primary {
  border-bottom: 5px solid var(--primary);
}
.b_top_p_primary {
  border-top: 5px solid var(--primary);
}

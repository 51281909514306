.card {
  padding: 1rem;
  border-radius: 2px;
  background-color: hsl(0, 0%, 100%);
  box-shadow: 0.25rem 0.25rem 1.5rem rgba(0 0 0/0.1);
  transition: transform 300ms ease;
}

// .card:hover {
//   transform: scale(1.02);
// }

.card__img {
  max-width: 100%;
  border-radius: 2px;
}

.card__content {
  padding: 0.2em 0.5em;
}

.card__desc {
  padding: 0 0.2em;
  font-size: 1rem;
}

/* attribution styling */

.attribution {
  position: absolute;
  bottom: 0;
  opacity: 0.9;
}

.attribution a {
  color: hsl(218, 44%, 22%);
  text-decoration: underline;
}

.heading_banner {
  font-size: 22px !important;
  line-height: 30px !important;
  //   margin-bottom: 20px !important;
  font-weight: 700 !important;
  letter-spacing: 0.3px;
  color: var(--heading);
}
.heading_banner2 p {
  font-size: 22px !important;
  line-height: 30px !important;
  //   margin-bottom: 20px !important;
  font-weight: 700 !important;
  letter-spacing: 0.3px;
  color: var(--heading);
}
.continue_div {
  overflow: hidden;
}
.continue_div span {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  display: inline-block;
  position: relative;
  padding-right: 25px;
  z-index: 1;
}
// .continue_div span:before {
//   display: inline-block;
//   content: "";
//   height: 2px;
//   // width: 1500px;
//   width: 100%;
//   background-color: var(--primary);
//   left: 100%;
//   top: 50%;
//   position: absolute;
//   z-index: 1;
// }

.Heading2 {
  font-size: 20px !important;
  line-height: 24px !important;
  //   margin-bottom: 20px !important;
  font-weight: 600 !important;
  letter-spacing: 0.3px;
  color: var(--heading);
}

@media screen and (max-width: 768px) {
  .heading_banner {
    font-size: 20px !important;
  }
  .heading_banner2 p {
    font-size: 20px !important;
  }
  .Heading2 {
    font-size: 18px !important;
  }
}

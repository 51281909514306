.cross_icon_abs{
    position:absolute
}







/* 







import React, { useState } from "react";
import { SecondaryButton } from "../../../components/buttons/Button";
import { Heading1 } from "../../../components/heading/Heading";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { Country, State, City } from "country-state-city";
import { useEffect } from "react";
import Validator from "validatorjs";
import { toast } from "react-toastify";
import { GET, POST } from "../../../constant/RequestAuthService";
import { useDispatch, useSelector } from "react-redux";
import "./Address.css";
import { Button } from "@mui/material";

const Address = () => {
  //
  //Data to be get
  //
  const [city, setCity] = useState([]);
  const [state, setState] = useState([]);
  const [country] = useState(
    Country.getAllCountries().find((country) => country.name === "India")
  );
  //
  //Data to be post
  //
  const [address, setAddress] = useState("");
  const [landmark, setLandmark] = useState("");
  const [area, setArea] = useState("");
  const [pincode, setPincode] = useState("");
  const [postState, setPostState] = useState("");
  const [postCity, setPostCity] = useState("");
  const [stateCode, setStateCode] = useState("");
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [stateChange, setStateChange] = useState(false);
  const [initialState, setinitialState] = useState(false);

  const savedAddress = useSelector(
    (state) => state.userReducer.userProfile.data
  );

  useEffect(() => {
    const address = savedAddress.addresses;

    if (address !== null) {
      setAddress(address.address);
      setLandmark(address.landmark);
      setArea(address.area);
      setPincode(address.pincode);
      setPostState(address.stateCode);
      setPostCity(address.cityName);
      setinitialState(true);
      setTimeout(() => {
        setinitialState(false);
      }, 300);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setState(State.getStatesOfCountry("IN"));
    setCity(City.getCitiesOfState("IN", postState));
  }, [initialState]);

  useEffect(() => {
    const findState = State.getAllStates().find(
      (state) => state.isoCode === postState
    );
    // console.log(findState);

    if (findState) {
      setCity(
        City.getAllCities().filter(
          (city) => city.stateCode === findState.isoCode
        )
      );
      setStateCode(findState.isoCode);
      setLat(findState.latitude);
      setLong(findState.longitude);
    }
  }, [postState]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setLoading(true);
    const requestData = {
      address,
      landmark,
      area,
      pincode,
      cityName: postCity,
      countryCode: "IN",
      stateCode,
      latitude: lat,
      longitude: long,
    };
    console.log(requestData);

    const checkdata = {
      address: "required",
      landmark: "required",
      area: "required",
      pincode: "required|digits:6",
      cityName: "required",
      stateCode: "required",
      countryCode: "required",
      latitude: "required",
      longitude: "required",
    };
    const validation = new Validator(requestData, checkdata);

    if (validation.fails()) {
      // setLoading(true);
      const errorData = Object.values(validation.errors.errors);
      errorData.map((x) => toast.error(`${x}`));
      // setLoading(false);
    } else {
      const response = await POST("address", requestData);
      console.log(response.status);
      if (response.status === 200) {
        getProfile();
        // setLoading(true);
        toast.success(response.message);
        // setLoading(false);

        // window.location.reload();
      } else {
        toast.error(response.message);
        // setLoading(true);
        // setLoading(false);
      }
    }
  };

  const dispatch = useDispatch();
  const getProfile = async () => {
    const profile = await GET("profile");
    // console.log(profile);
    if (profile.status === 200) {
      await dispatch({ type: "userProfile", payload: profile });
      // window.location.reload();
    }
  };

  const handleChangeState = (e) => {
    console.log("hy");
    setPostState(e.target.value);
    setStateChange(true);
    setTimeout(() => {
      setStateChange(false);
    }, 300);
  };

  useEffect(() => {
    stateChange === true && setPostCity(city[0].name);
  }, [stateChange]);

  console.log(postCity);

  return (
    <>
      <Heading1 classNames="py-4" title="Manage Address" />

      <div>
        <form onSubmit={handleSubmit}>
          <div
            className="d-flex align-items-center flex-wrap mt-3"
            style={{ gap: "16px" }}
          >
            <input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Address"
              className=" w-100 reg_sm_field w-100"
            />
            <input
              type="text"
              value={landmark}
              onChange={(e) => setLandmark(e.target.value)}
              placeholder="landmark"
              className=" w-100 reg_sm_field w-100"
            />
          </div>
          <div
            className="d-flex align-items-center flex-wrap flex-column flex-md-row mt-3"
            style={{ gap: "16px" }}
          >
            <input
              type="text"
              value={area}
              onChange={(e) => setArea(e.target.value)}
              placeholder="area"
              className=" w-100 reg_sm_field w-100"
            />
            <input
              type="text"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
              placeholder="pincode"
              className=" w-100 reg_sm_field w-100"
            />
          </div>
          <div
            className="d-flex align-items-center flex-wrap flex-column flex-md-row mt-3"
            style={{ gap: "16px" }}
          >
            <select
              // name="gender_id"
              name="myCountry"
              // value={registerData.gender_id}

              className="reg_sm_field w-100 "
            >
              {" "}
              <option>{country.name}</option>
            </select>
            <select
              name="myState"
              value={postState}
              onChange={(e) => handleChangeState(e)}
              className="reg_sm_field w-100 "
            >
              {state && state.length > 0
                ? state.map((data, i) => (
                    <option key={i} value={data.isoCode}>
                      {data.name}
                    </option>
                  ))
                : ""}
            </select>{" "}
            <select
              name="myCity"
              value={postCity}
              onChange={(e) => setPostCity(e.target.value)}
              className="reg_sm_field w-100 "
            >
              {city && city.length > 0
                ? city.map((data, i) => (
                    <option key={i} value={data.id}>
                      {data.name}
                    </option>
                  ))
                : ""}
            </select>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-4">
            <SecondaryButton title="Update" />
            <Button
              variant="text"
              className="text-capitalize p-0 fw_500"
              sx={{ color: "var(--primary)", fontSize: "14px" }}
            >
              <FmdGoodIcon
                className="me-1"
                sx={{ marginTop: "-3px", width: "1.3rem" }}
              />
              Use my Current Location
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Address; */
